/* Add the hover animation styles */
.dashboard-tile {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-tile:hover {
    transform: translateY(-10px);
}

.dashboard-tile .MuiPaper-root {
    transition: 0.3s ease;
    border: 2px solid transparent;
    /* background: linear-gradient(143deg, rgba(85, 85, 85, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(92, 92, 92, 0.2) 100%) */
}

.dashboard-tile:hover .MuiPaper-root {
    transition: background-color 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.5);
    /* background: linear-gradient(143deg, rgba(85, 85, 85, 0.2) 0%, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.2) 60%, rgba(92, 92, 92, 0.2) 100%) */
}


.dashboard-tile .MuiSvgIcon-root {
    transition: 0.3s ease;
}

.dashboard-tile:hover .MuiSvgIcon-root {
    transform: scale(1.5);
    transition: 0.3s ease;
}


