 body {
  background-image: url('https://img.freepik.com/free-photo/abstract-luxury-blur-grey-color-gradient-used-as-background-studio-wall-display-your-products_1258-54073.jpg?semt=ais_hybrid');
  background-color: none;
  /* background: linear-gradient(135deg, #414141, #525252); */
  height: 100vh;
  background-blend-mode: soft-light;
  background-size: cover;
  background-repeat: no-repeat;
 }

 .blurred {
  position: relative;
  background-color: unset !important;
  overflow: hidden;
 }

 .blurred::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: -1;
 }

  .entity-details-header {
  position: sticky;
  top: 0;
  z-index: 3;
  /* margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px */
 }

 .entity-details-button { 
  height: 80px;
  width: 80px;
 }

 .entity-details-button.MuiButton-startIcon { 
  height: 60px;
  width: 60px;
 }
 
 
 /* :root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
} */
