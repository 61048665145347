 #root {
    /* max-width: 1280px;
    margin: 0 auto;
    padding: 2rem; */
    text-align: center;
}
/*
th, td {
    padding-left: 1rem;
    padding-right: 1rem;
} */