
.message-container {
    display: flex;
    margin-bottom: 10px;
  }
  
  .message-container.own-message {
    justify-content: flex-end;
  }
  
  .message-container.other-message {
    justify-content: flex-start;
  }
  
  .bubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
  }
  
  .own-bubble {
  }
  
  .other-bubble {
  }
  
  .sender, .receiver {
    font-size: 0.85rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .timestamp {
    font-size: 0.75rem;
    margin-top: 5px;
    text-align: right;
  }
  